.cursor {
  position: fixed;
  z-index: 100;
  transform: translate(-50%, -50%);
  width: 3rem;
  height: 3rem;
  border: 2px dotted white;
  border-radius: 50%;
  pointer-events: none;
  transform-origin: 100% 100%;
}
