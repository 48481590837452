@use "../../styles/mixins.scss" as *;

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  font-size: 0.8rem;

  @include tablet {
    padding: 1.5rem 3rem;
    font-size: 1rem;
  }

  &__contact,
  &__name,
  &__resume {
    min-width: 5rem;
  }

  &__resume {
    text-align: right;
  }

  &__name {
    @include tablet {
      font-size: 1rem;
    }
  }
}
