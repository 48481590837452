@use "./styles/global.scss" as *;
@use "./styles/reset.scss" as *;

#light {
  background-color: white;
  p,
  span,
  a,
  h1,
  h2,
  h3,
  h4 {
    color: black;
    border-color: black;
  }

  .toggle {
    background-color: black;

    &__button {
      background: white;
    }
  }

  .others {
    &__title {
      color: black;
      border: 2px solid black;

      &:hover {
        color: white;
        background-color: black;
      }
    }
  }

  .contact {
    &__interest,
    &__social {
      color: black;
      border: 2px solid black;

      &:hover {
        color: white;
        background-color: black;
      }
    }
  }

  .cursor {
    border-color: black;
  }
}

#dark {
  background-color: black;

  p,
  span,
  a,
  h1,
  h2,
  h3,
  h4 {
    color: white;
    border-color: white;
  }

  .toggle {
    background-color: white;

    &__button {
      background: black;
    }
  }

  .others {
    &__title {
      color: white;
      border: 2px solid white;

      &:hover {
        color: black;
        background-color: white;
      }
    }
  }

  .contact {
    &__interest,
    &__social {
      color: white;
      border: 2px solid white;

      &:hover {
        color: black;
        background-color: white;
      }
    }
  }

  .cursor {
    border-color: white;
  }
}
