@use "../../styles/variables.scss" as *;
@use "../../styles/mixins.scss" as *;

.projects {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15rem;

  @include tablet {
    margin-top: 0;
    overflow: hidden;
  }

  @include desktop {
    margin-top: min(65vw, 30rem);
  }

  &__intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  &__title {
    font-family: $text-header-font;
    font-size: 9vw;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  &__description {
    width: min(100%, 20rem);
    text-align: center;

    @include tablet {
      width: 28rem;
    }
  }

  &__first,
  &__second,
  &__third {
    margin-bottom: 2rem;

    @include tablet {
      margin-bottom: 6rem;
    }
  }
}

.project {
  width: 100%;
  box-sizing: border-box;
  position: relative;

  @include tablet {
    padding: 0 2rem;
  }

  @include desktop {
    padding: 0 8rem;
  }

  &__title {
    font-size: 8vw;
    margin-bottom: 2rem;
    text-transform: uppercase;
    overflow: hidden;
  }

  &__description {
    margin-bottom: 1rem;

    &--space {
      margin-bottom: 3.5rem;

      @include tablet {
        margin-bottom: 10.5rem;
      }
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  &__image-container {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @include tablet {
      padding: 0 5rem;
    }

    &--front-screen {
      @include tablet {
        margin: 4rem auto 0 auto;
        max-width: 55rem;
      }
    }
  }

  &__image {
    width: 80%;
    max-width: 80rem;
    cursor: pointer;

    &--large {
      width: 100%;
    }

    &-text {
      display: none;

      @include tablet {
        white-space: nowrap;
        display: block;
        width: 200%;
        text-align: center;
        position: absolute;
        font-size: 3rem;
        z-index: 0;
        border-top: 0.5rem solid white;
        border-bottom: 0.5rem solid white;
        top: 45%;
        left: 50%;
        transform: translate(-50%, 140%);
        z-index: 0;
      }

      @include desktop {
        top: 50%;
      }

      &--align {
        @include tablet {
          top: 50%;
        }
      }
    }
  }
}

.others {
  @include tablet {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__title {
    color: black;
    display: inline-block;
    z-index: 3;
    position: relative;
    margin-bottom: 3rem;
    padding: 0.5rem 1rem;
    color: white;
    border: 2px solid white;
    border-radius: 2rem;
    transition: background-color 1s ease-out;
    cursor: pointer;

    &:hover {
      color: black;
      background-color: white;
      transition: background-color all 1s ease-out;
    }

    &--top {
      margin-top: 13.25px;
    }
  }

  &__image-container {
    display: none;

    @include tablet {
      display: block;
      width: 40vw;
      position: relative;
      height: 30vw;
    }
  }

  &__image {
    @include tablet {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      visibility: hidden;
    }
  }
}

.circle {
  display: none;

  @include tablet {
    display: inline-block;
    width: 8rem;
    position: absolute;
  }

  &__first {
    top: -80%;

    @include desktop {
      top: -50%;
      left: 20%;
    }
  }

  &__second {
    display: none;
    @include desktop {
      display: block;
      top: 15%;
      left: -20%;
    }
  }

  &__third {
    display: none;
    @include desktop {
      display: block;
      top: 8%;
      right: 5%;
    }
  }

  &__top {
    transform: translateY(8.44rem);
  }
}
