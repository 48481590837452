@use "../../styles/variables.scss" as *;
@use "../../styles/mixins.scss" as *;

.contact {
  padding: 0 1rem;
  margin-top: 2rem;
  overflow: hidden;

  @include tablet {
    padding: 0 2rem;
  }

  @include desktop {
    padding: 0 9rem 3rem 9rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
  }

  &__title-container {
    overflow: hidden;

    @include desktop {
      font-size: 5vw;
      min-width: 44vw;
    }
  }

  &__title-text {
    font-family: $text-header-font;
    font-size: 9.5vw;

    @include desktop {
      font-size: 8vw;
    }

    &--bottom {
      @include desktop {
        margin-top: 1rem;
        line-height: 0.95;
      }
    }
  }

  &__interests {
    margin: 1rem 0;

    @include desktop {
      max-width: 28rem;
    }
  }

  &__interests-intro {
    margin: 1rem 0;
  }

  &__interest {
    text-transform: uppercase;
    border: 2px solid white;
    border-radius: 2rem;
    padding: 0.5rem 1rem;
    margin: 0 1rem 1rem 0;
    transition: all 1s ease-in-out;
    cursor: pointer;

    &:hover {
      color: black;
      background-color: white;
    }

    @include tablet {
      display: inline-block;
    }

    @include desktop {
      &--right {
        margin-left: 2vw;
      }

      &--left {
        margin-left: -1vw;
      }
    }
  }

  &__socials {
    border-top: 1px solid white;
    display: flex;
    justify-content: flex-start;
    padding: 1rem 0;
    border-top: 1px solid white;
    margin: 0 -2rem;
  }

  &__social {
    display: inline-block;
    border: 2px solid white;
    border-radius: 2rem;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    margin-right: 1rem;
    transition: all 1s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: white;
      color: black;
    }

    &--left {
      margin-left: 2rem;
    }
  }
}
