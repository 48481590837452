@use "./variables.scss" as *;
@use "./mixins.scss" as *;

html {
  box-sizing: border-box;
}

*,
*::after,
*::before  {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: $text-body-font;
  background-color: black;
  color: white;
  overflow-x: hidden;
}

h1,
h2,
h3 {
  margin: 0;
  font-family: $text-header-font;
  font-weight: 500;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: white;
}
