@use "../../styles/variables.scss" as *;
@use "../../styles/mixins.scss" as *;

.hero {
  padding: 0 1rem;
  height: calc(150vh - 3.3438rem);
  position: relative;

  @include tablet {
    height: calc(100vh - 3.3438rem);
  }

  &__title {
    font-family: $text-header-font;
    font-weight: 500;
    display: block;
    position: absolute;
    text-transform: uppercase;
    z-index: 1;

    &--dev {
      position: unset;
      width: 100%;
      text-align: center;
      font-size: 9vw;
      margin-bottom: 1rem;

      @include tablet {
        order: 2;
        margin-bottom: 0;
        transform: translateY(-5vw);
      }

      @include desktop {
        transform: translateY(5vw);
      }
    }

    &--full-stack {
      @extend .hero__title--dev;
      display: none;

      @include tablet {
        display: block;
        order: 1;
        transform: translateY(8vw);
        font-size: 15vw;
        overflow: hidden;
      }

      @include desktop {
        transform: translateY(-3rem);
      }
    }

    &--full {
      top: 7vw;
      left: 5vw;
      font-size: 20vw;

      @include tablet {
        display: none;
      }
    }

    &--stack {
      top: min(60vw, 12rem);
      right: 5vw;
      font-size: 20vw;

      @include tablet {
        display: none;
      }
    }
  }

  &__notes {
    @extend .hero__title;
    top: max(8rem, 20vw);
    right: 2.5rem;
    font-size: 0.8rem;

    @include tablet {
      top: calc(50vh - 3.3438rem);
      left: max(72vw, 35rem);
    }

    @include desktop {
      top: calc(54vh - 3.3438rem);
      display: flex;
      justify-content: space-between;
      left: 50vw;
      transform: translate(-50%, 5vw);
      min-width: 84vw;
      font-size: 1rem;
    }
  }

  &__right-note {
    max-width: 13rem;
    display: block;
    overflow: hidden;

    @include desktop {
      max-width: 14rem;
    }
  }

  &__left-note {
    display: none;
    overflow: hidden;

    @include desktop {
      display: block;
      width: 14rem;
      text-align: right;
    }
  }

  &__main-content {
    position: absolute;
    margin: 0 auto;
    width: 100%;
    left: 0;
    top: min(90vw, 22rem);

    @include tablet {
      top: 50%;
      transform: translate(0, -50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @include desktop {
      transform: translate(0, -20%);
    }
  }

  &__image-wrapper {
    width: calc(100% - 2rem);
    max-width: 19rem;
    bottom: -10vh;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    overflow: hidden;

    @include tablet {
      order: 1;
      max-width: 20rem;
    }

    @include desktop {
      scale: (1.7);
    }
  }

  &__image {
    width: 100%;
  }
}

span {
  display: block;
}
