@use "../../styles/mixins.scss" as *;

.about {
  padding: 0 1rem;
  border-top: 1px solid white;
  border-radius: 0.5px;
  margin-top: 2rem;

  @include tablet {
    padding: 0 2rem;
    display: flex;
    align-items: flex-start;
  }

  @include desktop {
    padding: 0 9rem 4rem 9rem;
  }

  &__author-name {
    font-size: 8vw;
    margin: 2rem 0 2rem auto;
    max-width: 28rem;
    text-align: right;
    text-transform: uppercase;

    @include tablet {
      overflow: hidden;
      order: 2;
    }

    @include desktop {
      max-width: 39rem;
    }
  }

  &__info {
    @include tablet {
      margin-top: 15vw;
      max-width: 26rem;
    }
  }

  &__text {
    margin-bottom: 1rem;

    @include tablet {
      order: 1;
      overflow: hidden;
    }
  }
}
