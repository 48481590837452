@use "../../styles/mixins.scss" as *;
@use "../../styles/variables.scss" as *;

.skills {
  padding: 0 1rem;

  @include tablet {
    padding: 0 2rem;
  }

  @include desktop {
    padding: 0 8rem;
  }

  &__title {
    font-family: $text-header-font;
    font-size: 9vw;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  &__wrapper {
    @include flex-layout(row, center, center);
    flex-wrap: wrap;
    max-width: 478px;
    margin: 0 auto;
    position: relative;
    min-height: 800px;
    margin-top: 2rem;

    @include tablet {
      max-width: 768px;
      min-height: 900px;
    }

    @include desktop {
      max-width: 1000px;
    }
  }

  &__icon {
    font-size: 4rem;

    @include tablet {
      font-size: 5rem;
    }
  }
}

.icon {
  &-javascript {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-typescript {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-html {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-react {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include desktop {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
    }
  }

  &-nodejs {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-mongodb {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-css {
    position: absolute;
    top: 35%;
    left: 20%;
    transform: translate(-50%, -50%);
  }

  &-scss {
    position: absolute;
    top: 35%;
    left: 80%;
    transform: translate(-50%, -50%);
  }

  &-nextjs {
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-50%, -50%);
  }

  &-redux {
    position: absolute;
    top: 50%;
    left: 80%;
    transform: translate(-50%, -50%);
  }

  &-express {
    position: absolute;
    top: 65%;
    left: 20%;
    transform: translate(-50%, -50%);
  }

  &-mysql {
    position: absolute;
    top: 65%;
    left: 80%;
    transform: translate(-50%, -50%);
  }

  &-firebase {
    position: absolute;
    top: 95%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include desktop {
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translate(-50%, -50%);
    }
  }
}
