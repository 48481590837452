@use "../../styles/mixins.scss" as *;

.toggle {
  position: relative;
  max-width: 3.6rem;
  height: 1.8rem;
  background-color: white;
  border-radius: 1.875rem;
  margin: 1rem auto 1rem auto;
  cursor: pointer;

  @include tablet {
    margin-left: 0;
  }

  @include desktop {
    margin-right: 0;
    margin-left: auto;
  }

  &--mobile {
    @include tablet {
      display: none;
    }
  }

  &--tablet {
    display: none;

    @include tablet {
      display: block;
    }

    @include desktop {
      display: none;
    }
  }

  &__button {
    width: 1.25rem;
    height: 1.25rem;
    background: black;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-100%, -50%);
    border: none;
    transition: all 0.2s ease-in-out;
    box-shadow: inset 0 8px 40px rgba(255, 255, 255, 0.1),
      inset 0 4px 4px rgba(255, 255, 255, 0.1),
      inset 0 -4px 4px rgba(255, 255, 255, 0.1);

    &--active {
      transform: translate(0%, -50%);
      transition: all 0.2s ease-in-out;
    }
  }
}
